<template>
    <div class="card card-body shadow-sm infomations-list">
      <validation-observer
        ref="form_create_entry"
        v-slot="{ passes, valid, validated }"
      >
      <app-basic-table
        ref="basic_table"
        :table-name="$t('category.list_title')"
        :filters.sync="filters"
        :setting-columns="columns"
        :endpoint-list="ENDPOINT.KYY_CATEGORY_MENU_LIST"
        @resetAllSearch="onResetAllSearch"
        @searched="onSearch"
      >
        <template v-slot:filters>
          <div class="row">
            <!-- for restaurant -->
            <div class="col-xl-4 col-lg-6 col-sm-12">
              <div class="form-group">
                <label class="col-xl-12 col-lg-12 col-sm-12">{{ filters.restaurant_id.label }}</label>
                <div class="col-xl-12 col-lg-12 col-sm-12 gr-icheck mt-0">
                  <app-select
                    :name="filters.restaurant_id.name"
                    :options-data="restaurantList"
                    v-model="filters.restaurant_id.value"
                  />
                </div>
              </div>
            </div>

            <!-- for category -->
            <div class="col-xl-4 col-lg-6 col-sm-12">
              <div class="form-group">
                <label class="col-xl-12 col-lg-12 col-sm-12">{{ filters.category.label }}</label>
                <div class="col-xl-12 col-lg-12 col-sm-12 gr-icheck mt-0">
                  <app-select
                    :name="filters.category.name"
                    :options-data="categoryList"
                    v-model="filters.category.value"
                  />
                </div>
              </div>
            </div>

            <!-- for name -->
            <div class="col-xl-4 col-lg-6 col-sm-12">
              <div class="form-group">
                <label class="col-xl-12 col-lg-12 col-sm-12">{{ filters.name.label }}</label>
                <div class="col-xl-12 col-lg-12 col-sm-12 gr-icheck mt-0">
                  <app-input :name="filters.name.name" input-style="normal"
                             v-model="filters.name.value"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
  
        <template v-slot:body-cell-price="props">
        <td class="app-align-middle text-center">
          {{ convertCurrency(props.row.price, ) }}
        </td>
      </template>

        <template v-slot:table-menu-right>
          <button @click="handleBtnCreateClick()" class="btn btn-warning">
            {{ $t("common_vn.create") }}
          </button>
        </template>
  
        <template v-slot:body-cell-edit="props">
          <td class="app-align-middle text-center app-p-none">
            <button
              @click="handleBtnUpdateClick(props.row)"
              class="btn btn-primary"
            >
              {{ $t("common_vn.detail") }}
            </button>
          </td>
        </template>
  
        <template v-slot:body-cell-image_path="props">
          <td class="app-align-middle" style="text-align: center;">
            <img :src="props.row.image.url" v-if="props.row.image.path" style="width: 100px; height: 100px">
          </td>
        </template>
      </app-basic-table>
      </validation-observer>
    </div>
  </template>
  
  <script>
    import AppBasicTable from "@components/_common/list/AppBasicTable";
    import AppDateRangePicker from "../../../components/forms/AppDateRangepicker";
    import {ENDPOINT} from "../../../constants/api";
    export default {
      name: "CategoryMenuList",
      components: {
        "app-basic-table": AppBasicTable,
        AppDateRangePicker,
      },
      data() {
        return {
          idCache: null,
          paramGetList: {},
          filters: {
            restaurant_id: {
              name: "restaurant_id",
              condition: "equal",
              label: this.$t("restaurant.name")
            },
            name: {
              name: "name",
              condition: "like",
              label: this.$t("category_menu.name")
            },
            category: {
              name: "kyy_category_id",
              condition: "equal",
              label: this.$t("category_menu.category")
            },
          },
          columns: [
            {name: "restaurant_name", label: this.$t("restaurant.name"), sortable: true, textAlign: 'text-center'},
            {name: "name", label: this.$t("category_menu.name"), sortable: true, textAlign: 'text-center'},
            {name: "price", label: this.$t("category_menu.price"), sortable: true, textAlign: 'text-center'},
            // {name: "name_vn", label: this.$t("category_menu.name_vn"), sortable: true, textAlign: 'text-center'},
            {name: "category_name", label: this.$t("category_menu.category"), sortable: true, textAlign: 'text-center'},
            {name: "image_path", label: this.$t("category_menu.image"), textAlign: 'text-center'},
            {name: "edit", label: this.$t("common_vn.action"), textAlign: 'text-center'},
          ],
          meta: [],
          endPoint: '',
          categoryList: [],
          restaurantList: [],
        };
      },
      watch : {
        "filters.restaurant_id.value" : async function(value) {
          if (value) {
              let filters = {
                'filters[restaurant_id][equal]': value
              }

              let responseForCategory = await this.$request.get(this.ENDPOINT.KYY_CATEGORY_LIST_SELECT, filters);
              if (responseForCategory.hasErrors()) {
                if (responseForCategory.status === 'error'){
                  this.__noticeError(this.$t(responseForCategory.data.error_msg));
                }
                this.categoryList = [];
              } else {
                this.categoryList = responseForCategory.data.data.map(item => {
                  return {
                    id: item.id,
                    name: item.name
                  }
                });
              }
          }
        },
      },
      methods: {
        handleBtnCreateClick() {
          this.$router.push({name: this.ROUTES.ADMIN.KYY_CATEGORY_MENU_CREATE})
        },
        handleBtnUpdateClick(entry) {
          this.$router.push({name: this.ROUTES.ADMIN.KYY_CATEGORY_MENU_EDIT, params: {id: entry.id}})
        },
        onResetAllSearch() {
          this.$router.push({name: this.$route.name,
            query: {}
          })
          this.categoryList = [];
        },
        onSearch() {
          // this.getTourSelectName();
        },
      },

      mounted: async function () {
        let responseForRestaurant = await this.$request.get(this.ENDPOINT.KYY_RESTAURANT_LIST_SELECT);
        if (responseForRestaurant.hasErrors()) {
          if (responseForRestaurant.status === 'error'){
            this.__noticeError(this.$t(responseForRestaurant.data.error_msg));
          }
        } else {
          this.restaurantList = responseForRestaurant.data.data.map(item => {
            return {
              id: item.id,
              name: item.name
            }
          });
        }
      },
    }
  </script>
  